/* Selection */
::selection {
    color: inherit;
    background: transparent;
}

::-moz-selection {
    color: inherit;
    background: transparent;
}

/* Scroll */
::-webkit-scrollbar {
    background-color: #ffffff;
    width: 16px;
}

::-webkit-scrollbar-track {
    background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #ffffff;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
}

::-webkit-scrollbar-button {
    display: none;
}

/* Focus */
:focus {
    outline-color: transparent;
    outline: none;
}

/* Active */
:active {
    box-shadow: none;
}

/* Animation */
* {
    transition: all 500ms;
    -webkit-tap-highlight-color: transparent;
}

/* Font */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url('./assets/fonts/roboto/roboto300.woff2') format('woff2'),
        url('./assets/fonts/roboto/roboto300.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./assets/fonts/roboto/robotoRegular.woff2') format('woff2'),
        url('./assets/fonts/roboto/robotoRegular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('./assets/fonts/roboto/roboto500.woff2') format('woff2'),
        url('./assets/fonts/roboto/roboto500.woff') format('woff');
    font-display: swap;
}

/* Tags */
body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

iframe {
    border: none;
}

/* Toastify */
:root {
    --toastify-color-light: #ffffff;
    --toastify-color-dark: #090d15;
    --toastify-color-info: #7fe3fa;
    --toastify-color-success: #1ed760;
    --toastify-color-warning: #fdcb6c;
    --toastify-color-error: #ee585d;
    --toastify-color-transparent: rgba(255, 255, 255, 0.75);
    --toastify-toast-width: 260px;
    --toastify-toast-background: rgba(255, 255, 255, 0.5);
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'Roboto', sans-serif;
    --toastify-text-color-light: rgb(9 13 21 / 55%);
    --toastify-spinner-color: #090d15;
    --toastify-color-progress-dark: #ab98f2;
}

.Toastify__toast {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 10px 0 rgb(9 13 21 / 5%), 0 0 5px 0 rgb(9 13 21 / 2.5%);
}

/* Uppy */
.uppy-Container {
    width: 100%;
    max-height: 525px;
}

/* Select */
.uppy-Dashboard-inner {
    background-color: transparent;
    border: none;
    border-radius: 2.25px;
    max-height: 525px;

}

.uppy-Dashboard-innerWrap {
    color: #090d15;
}

[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
    border: 1px dashed #EAEAEA;
    margin: 0;
    max-height: 525px;

}

@media (max-width: 600px) {
    .uppy-Container {
        max-height: 500px;
    }

    /* Select */
    .uppy-Dashboard-inner {
        max-height: 500px;

    }

    [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
        max-height: 500px;

    }
}


.uppy-Dashboard-AddFiles-title {
    color: #090d15;
}

.uppy-Dashboard-AddFiles-title button {
    font-weight: 700;
    color: #090d15;
    border: none;
    text-decoration: none;
}

.uppy-Dashboard-AddFiles-title button:hover,
.uppy-Dashboard-AddFiles-title button:active,
.uppy-Dashboard-AddFiles-title button:visited,
.uppy-u-reset.uppy-Dashboard-browse {
    border: none;
    text-decoration: none;
}

/* Submit */
.uppy-DashboardContent-bar {
    border: none;
    background: transparent;
    padding: 0;
}

.uppy-DashboardContent-bar button,
.uppy-DashboardContent-bar button:hover {
    color: #090d15;
}

.uppy-Dashboard-files {
    border: 1px dashed #EAEAEA;
}

.uppy-Dashboard-Item {
    border: none;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
    border: none;
}

.uppy-StatusBar.is-preprocessing {
    display: none;
}

.uppy-StatusBar.is-waiting:before,
.uppy-StatusBar.is-waiting:after {
    content: none;
}


.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
    padding: 0;
    background: transparent;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload,
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
    background-color: #090d15;
    outline: none;
    box-shadow: none;
}

.uppy-Dashboard-progressindicators,
.uppy-Dashboard-progressindicators button {
    color: #090d15;
}

.uppy-StatusBar.is-uploading .uppy-StatusBar-progress {
    display: none;
}

.uppy-StatusBar.is-uploading svg,
.uppy-StatusBar-content svg {
    fill: #090d15;
}

.uppy-StatusBar.is-uploading:before,
.uppy-StatusBar.is-uploading:after {
    background: transparent;
    border: none;
    content: none;

}

.uppy-StatusBar.is-error .uppy-StatusBar-progress,
.uppy-StatusBar.is-error:before,
.uppy-StatusBar.is-error:after {
    background: transparent;
    border: none;
    content: none;
}

.uppy-StatusBar-actions button.uppy-StatusBar-actionBtn--retry {
    background-color: #090d15;
    color: #ffffff;
    font-size: 12px;
}